import React from "react";
import { motion, AnimatePresence } from "framer-motion";

function Heart(props) {
  const ease = props.ease;
  const hands = props.items.map(value => (
    <motion.div
      key={value.id}
      className="hands-container"
      initial={{
        opacity: 0,
        y: value.y + 150,
        x: value.x,
        scale: value.scale * 0.6
      }}
      animate={{
        opacity: [0, 1, 1, 1, 0],
        y: -120,
        x: [
          value.x,
          value.x2,
          value.x2 + value.x2 * 0.2,
          value.x2,
          value.x2 + value.x2 * 0.2
        ],
        rotate: [0, -5, 5, -5, 5, -5],
        scale: [0, value.scale, value.scale, value.scale, value.scale]
      }}
      transition={{ ease: ease, duration: 8 / (value.scale * 1.4) }}
      positionTransition
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      style={{zIndex: value.scale > .5 ? 100 : -1 }}
    >
      <img src={props.image.src} className="jazzhands" alt="hands" />
    </motion.div>
  ));

  return <AnimatePresence>{hands}</AnimatePresence>;
}

export default Heart;
