import React from "react";
import { Helmet } from "react-helmet";
import withLocation from "./withLocation";

function Layout(props) {
  const { children } = props;
  return (
    <div>
      <Helmet>
        <meta
          name="viewport"
          content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={props.ogTitle} />
        <meta property="og:description" content={props.desc} />
        <meta
          property="og:image"
          content={"http://heart.raddaenstockholmare.se/res-og.jpg"}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="720" />
        <title>{props.title}</title>
      </Helmet>
      <main>{children}</main>
    </div>
  );
}

export default withLocation(Layout);
